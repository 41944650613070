import { useState } from "react";
import { ADD_SUBSCRIPTION } from "../../../services/index";
import { v4 as uuidv4 } from 'uuid';
import "./Subscription.css";
import { useMutation } from "@apollo/client";

const Subscription = () => {
  const [addSubscription, { data, loading, error }] = useMutation(ADD_SUBSCRIPTION);
  const [validations, setValidations] = useState<string[]>([]);
  const [email, setEmail] = useState('');

  const submitHandler = () => {
    if (!email) {
      setValidations(['Error submitting form.', 'Check form inputs'])
      return;
    };
    // console.log(email);

    addSubscription({ variables: {
      input: {
        subscriptionId: uuidv4(),
        email: email,
        created_at: `${Date.now()}`,
        updated_at: `${Date.now()}`,
        isActive: true,
      }
    }});
    setEmail('');
    console.log('Data: ', data);
  }

  if (loading) return <p style={{ textAlign: "center" }}>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="subscription">
      <section className="subscription--main wrapper--container">
        <div className="subscription--title">
          <h1 className="subscription--heading">Subscribe</h1>
        </div>
        <div className="subscription--content">
          <div className="subscription--form">
            <div className="form">
              {/* {!!validations.length &&
                <div className="form--validations">
                  <ul>{validations.map((validation, i) => {
                    return <li key={i}>{validation}</li>
                  })}
                  </ul>
                </div>
              } */}
              <form action="">
                <div className="subscription--container">
                  <div className="subscription--item subscription--mid">
                    <label htmlFor="email" className="subscription--label">
                      <input
                        type="text"
                        className={`subscription--input ${!!validations.length? "subscription--error" : ""}`}
                        name="email"
                        id="email"
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                        placeholder="Enter email address"
                        aria-required="true"
                        aria-invalid="false"
                        required
                      />
                    </label>
                  </div>
                  <div className="subscription--item subscription--min">
                    <button
                      type="button"
                      className="subscription--button"
                      onClick={submitHandler}
                    >Subscribe <span className="button--arrow">&#10142;</span></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Subscription;
import { useState } from "react";
import { useMutation } from '@apollo/client';
import { ADD_CONTACT } from "../../services/index";
// import { ContactType } from "../../types/index";
// import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
// console.log(uuidv4())
// import { Header } from "../partials";
import './Contact.css';

const Contact = () => {
  // const ADD_CONTACT = gql`
  //   mutation addContact($input: addContactInput!) {
  //     addContact(input: $input) {
  //       id,
  //       first_name,
  //       last_name,
  //       email,
  //       phone_number,
  //       organization,
  //       country,
  //       created_at,
  //       message,
  //       contactId,
  //     }
  //   }
  // `;
  const [AddContact, { data, loading, error }] = useMutation(ADD_CONTACT);
  const [validations, setValidations] = useState<string[]>([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [organization, setOrganization] = useState('');
  const [country, setCountry] = useState('');
  const [message, setMessage] = useState('');
  // let input: ContactType;

  const submitHandler = () => {
    if(!firstName || !lastName || !email || !phoneNumber || !organization || !country || !message) {
      setValidations(['Error submitting form.', 'Check input forms'])
      return;
    };
    //   type Variables = {
    //     input: {
    //         method: string;
    //         metadata: Object;
    //     }
    // };
    // const id = uuidv4();
    // console.log(firstName, lastName, email, phoneNumber, organization, country, message);
    AddContact({ variables: {
      input: {
        contactId: uuidv4(),
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
        organization: organization,
        country: country,
        message: message,
        isRead: false,
        created_at: `${Date.now()}`
      }
    }});
    // input.value = '';
    console.log('Data: ', data);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setOrganization('');
    setCountry('');
    setMessage('');
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="contact">
      {/* <section className="contact--header">
        <Header />
      </section> */}
      <section className="contact--main wrapper--container">
        <div className="contact--title">
          <h1 className="contact--heading">Contact Us</h1>
        </div>
        <div className="contact--content">
          <div className="contact--form">
            <div className="form">
              {!!validations.length &&
                <div className="form--validations">
                  <ul>{validations.map( (validation, i ) => {
                    return <li key={i}>{ validation }</li>
                  })}
                  </ul>
                </div>
              }
              <form action="">
                <div className="form--container">
                  <div className="form--item">
                    <label htmlFor="firstName" className="form--label form--required">First Name:</label>
                    <input 
                      type="text" 
                      className="form--input" 
                      name="firstName" 
                      id="firstName" 
                      value={firstName}
                      onChange={(event) => {
                        // const value = event.target.value;
                        setFirstName(event.target.value);
                      }} 
                      placeholder="Enter first name" 
                      aria-required="true" 
                      aria-invalid="false" 
                      required 
                    />
                  </div>
                  <div className="form--item">
                    <label htmlFor="lastName" className="form--label form--required">Last Name: </label>
                    <input 
                      type="text" 
                      className="form--input" 
                      name="lastName" 
                      id="lastName"
                      value={lastName}
                      onChange={(event) => {
                        setLastName(event.target.value);
                      }}  
                      placeholder="Enter last name" 
                      aria-required="true" 
                      aria-invalid="false" 
                      required 
                    />
                  </div>
                  <div className="form--item">
                    <label htmlFor="email" className="form--label form--required">Business Email: </label>
                    <input 
                      type="email" 
                      className="form--input" 
                      name="email" 
                      id="email"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      placeholder="Enter business email" 
                      aria-required="true" 
                      aria-invalid="false" 
                      required 
                    />
                  </div>
                  <div className="form--item">
                    <label htmlFor="phoneNumber" className="form--label form--required">Phone number:</label>
                    <input 
                      type="tel" 
                      className="form--input" 
                      name="phoneNumber" 
                      id="phoneNumber" 
                      value={phoneNumber}
                      onChange={(event) => {
                        setPhoneNumber(event.target.value);
                      }}
                      placeholder="Enter phone number" 
                      aria-required="true" 
                      aria-invalid="false" 
                      required 
                    />
                  </div>
                  <div className="form--item">
                    <label htmlFor="organization" className="form--label form--required">Organization: </label>
                    <input 
                      type="text" 
                      className="form--input" 
                      name="organization" 
                      id="organization"
                      value={organization}
                      onChange={(event) => {
                        setOrganization(event.target.value);
                      }} 
                      placeholder="Enter organization name" 
                      aria-required="true" 
                      aria-invalid="false" 
                      required 
                    />
                  </div>
                  <div className="form--item">
                    <label htmlFor="country" className="form--label form--required">Country: </label>
                    <input 
                      type="text" 
                      className="form--input" 
                      name="country" 
                      id="country"
                      value={country}
                      onChange={(event) => {
                        setCountry(event.target.value);
                      }} 
                      placeholder="Enter country name" 
                      aria-required="true" 
                      aria-invalid="false" 
                      required 
                    />
                  </div>
                  <div className="form--item form--text">
                    <label htmlFor="message" className="form--label form--required">Message: </label>
                    <textarea 
                      className="form--input" 
                      name="message" 
                      id="message"
                      value={message}
                      onChange={(event) => {
                        setMessage(event.target.value);
                      }}
                    >
                    </textarea>
                  </div>
                </div>
                <div>
                  <button 
                    type="button" 
                    className="form--button"
                    onClick={submitHandler}
                  >Submit  <span className="button--arrow">&#10142;</span></button>
                </div>
              </form>
            </div>
          </div>
          <div className="contact--support">
            <div className="support--item">
              <h3 className="contact--heading">Need more Information?</h3>
              <p className="contact--heading">For enquiries, send us an email on: &nbsp;<br /><a href="mailto:info@barewave.com">info@barewave.com</a></p>
            </div>
            <div className="support--item">
              <h3 className="contact--heading">Need Customer Support?</h3>
              <p className="contact--heading">Call our customer support now on: &nbsp;<br /><a href="tel:+23408032130560">+234-(803)-213-0560</a> </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
// import { Link } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { Header } from "../partials";
import './Career.css';

const Career = () => {
  // const [careers, setCareers] = useState<string[]>([]);
  // const [validations, setValidations] = useState<string[]>([]);

  // useEffect(() => {
  //   console.log('careers: ', careers);
  //   console.log('validations: ', validations);
  // }, [careers, validations]);

  return (
    <div className="career">
      {/* <section className="career--header">
        <Header />
      </section> */}
      <section className="career--main wrapper--container">
        <div className="career--title">
          <h1 className="career--heading">Join Us</h1>
        </div>
        <div className="career--content">
          <p>No job currently listed.<br /> Check back later or get in touch with <a href="mailto:career@barewave.com">career@barewave.com</a>.</p>
        </div>
      </section>
    </div>
  );
};

export default Career;

// import { Header } from "../partials";
import './Saas.css';

const Saas = () => {

  return (
    <div className="saas">
      {/* <section className="saas--header">
        <Header />
      </section> */}
      <section className="saas--main wrapper--container">
        <div className="saas--title">
          <h1 className="saas--heading">SaaS Applications</h1>
        </div>
        <div className="saas--content">
          <p>
            Barewave is a fast growing Information Technology startup that leverages cloud-based platforms and emerging technologies to solve real life problems across various fields. Our approach to problem solving utilizes software as a tool rather than a solution. This has enabled us to assess constraints holistically while deploying the right software.
          </p>
          <p>
            We are leveraging data-informed design and iterative design process to deliver services and products that are accessible, available and reliable to improve end-users’ experience. Hence, by adopting continuous improvement and operational excellence, we add real values to end-users by substituting their actual experiences with their desired expectations thereby ensuring optimal differentiations.
          </p>
          <p>
            To learn more saas our various offerings or enquiries, do send us an email &nbsp;<a href="mailto:info@barewave.com">info@barewave.com</a> or call:&nbsp;<a href="tel:+2348032130560">+234 803 213 0560</a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Saas;
import { Link, useNavigate } from "react-router-dom";
import './Home.css';
// import hero from '../../assets/hero.jpg';
// import cluster from '../../assets/cluster.svg';
// import { Header } from "../partials/";

const Home = () => {
  const navigate = useNavigate();

  const navigateToDemo = () => {
    // 👇️ navigate to /
    navigate('/demo');
  };

  return (
    <div className="home">
      {/* <section className='home--header'>
        <Header />
      </section> */}
      <section className="home--main">
        <div className='home--hero'>
          <div className="hero">
            <div className='hero--text'>
              <h2 className="hero--title"> The <span className="green">SaaS</span> platform<br /> for every day users. </h2>
            </div>
            <div className='hero--buttons'>
              {/* <button type='button' className='hero--button'>Request a demo</button> */}
              <button type='button' className='hero--button' onClick={navigateToDemo}>Request a demo</button>
            </div>
          </div>
          <div className="hero">
            <div className='hero--images'>
              {/* <img src={cluster} className='hero--image' alt='' /> */}
              <svg className="shadow hero--image" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="888" height="710.80704" viewBox="0 0 888 710.80704" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>server_cluster</title>
                <path d="M600.93555,699.40352a260.06372,260.06372,0,1,1,183.89355-76.1709A258.36518,258.36518,0,0,1,600.93555,699.40352Zm0-518.12891c-142.29688,0-258.06446,115.76758-258.06446,258.06494s115.76758,258.064,258.06446,258.064S859,581.63692,859,439.33955,743.23242,181.27461,600.93555,181.27461Z" transform="translate(-156 -94.59648)" fill="#ccc" />
                <rect x="469" y="509.90391" width="262" height="195" transform="translate(1051.40391 -87.19257) rotate(90)" fill="#2f2e41" />
                <rect x="313" y="373.80743" width="262" height="104" fill="#3f3d56" />
                <rect x="313" y="489.80743" width="262" height="104" fill="#3f3d56" />
                <rect x="313" y="605.80743" width="262" height="104" fill="#3f3d56" />
                <rect x="313" y="417.90372" width="262" height="16" fill="#6c63ff" />
                <rect x="313" y="533.90372" width="262" height="16" fill="#6c63ff" />
                <rect x="313" y="649.90372" width="262" height="16" fill="#6c63ff" />
                <circle cx="524" cy="388.80743" r="6" fill="#6c63ff" />
                <circle cx="545" cy="388.80743" r="6" fill="#6c63ff" />
                <circle cx="566" cy="388.80743" r="6" fill="#6c63ff" />
                <circle cx="524" cy="502.80743" r="6" fill="#6c63ff" />
                <circle cx="545" cy="502.80743" r="6" fill="#6c63ff" />
                <circle cx="566" cy="502.80743" r="6" fill="#6c63ff" />
                <circle cx="524" cy="616.80743" r="6" fill="#6c63ff" />
                <circle cx="545" cy="616.80743" r="6" fill="#6c63ff" />
                <circle cx="566" cy="616.80743" r="6" fill="#6c63ff" />
                <path d="M397.8787,365.66116v185.9917a14.67525,14.67525,0,0,1-14.67227,14.67231H310.928a14.67656,14.67656,0,0,1-14.6784-14.67231V365.66116a14.67655,14.67655,0,0,1,14.6784-14.67228h8.76786a6.9778,6.9778,0,0,0,6.4551,9.60614h41.20843a6.97774,6.97774,0,0,0,6.45506-9.60614h9.392A14.67524,14.67524,0,0,1,397.8787,365.66116Z" transform="translate(-156 -94.59648)" fill="#e6e6e6" />
                <rect x="145.06414" y="291.91002" width="92" height="128" fill="#fff" />
                <rect x="171.18557" y="379.92719" width="36.71978" height="2.09827" fill="#e6e6e6" />
                <rect x="171.14869" y="387.63152" width="56.81724" height="2.09827" fill="#6c63ff" />
                <rect x="171.14869" y="394.89011" width="44.5883" height="2.09827" fill="#e6e6e6" />
                <rect x="171.14869" y="402.1487" width="26.22841" height="2.09827" fill="#e6e6e6" />
                <rect x="171.14869" y="409.40729" width="39.34262" height="2.09827" fill="#e6e6e6" />
                <rect x="154.53324" y="379.32379" width="4.19655" height="4.19655" fill="#e6e6e6" />
                <rect x="154.53324" y="386.58238" width="4.19655" height="4.19655" fill="#6c63ff" />
                <rect x="154.53324" y="393.84097" width="4.19655" height="4.19655" fill="#e6e6e6" />
                <rect x="154.53324" y="401.09956" width="4.19655" height="4.19655" fill="#e6e6e6" />
                <rect x="154.53324" y="408.35815" width="4.19655" height="4.19655" fill="#e6e6e6" />
                <rect x="172.75082" y="307.91002" width="36.99753" height="36.99753" fill="#e6e6e6" />
                <rect x="321.11058" y="0.13939" width="245.88383" height="157.47494" fill="#e6e6e6" />
                <rect x="339.64671" y="44.19534" width="203.86136" height="77.03517" fill="#fff" />
                <rect x="376.78939" y="57.54179" width="48.92684" height="2.79582" fill="#e6e6e6" />
                <rect x="376.74025" y="67.80733" width="75.70547" height="2.79582" fill="#6c63ff" />
                <rect x="376.74025" y="77.47895" width="59.41117" height="2.79582" fill="#e6e6e6" />
                <rect x="376.74025" y="87.15058" width="34.94775" height="2.79582" fill="#e6e6e6" />
                <rect x="376.74025" y="96.8222" width="52.42162" height="2.79582" fill="#e6e6e6" />
                <rect x="354.60119" y="56.7378" width="5.59164" height="5.59164" fill="#e6e6e6" />
                <rect x="354.60119" y="66.40942" width="5.59164" height="5.59164" fill="#6c63ff" />
                <rect x="354.60119" y="76.08104" width="5.59164" height="5.59164" fill="#e6e6e6" />
                <rect x="354.60119" y="85.75267" width="5.59164" height="5.59164" fill="#e6e6e6" />
                <rect x="354.60119" y="95.42429" width="5.59164" height="5.59164" fill="#e6e6e6" />
                <rect x="483.44507" y="53.8475" width="50.05873" height="50.05873" fill="#e6e6e6" />
                <rect x="321.00559" width="245.88383" height="10.44604" fill="#6c63ff" />
                <circle cx="328.76825" cy="5.34057" r="2.09686" fill="#fff" />
                <circle cx="336.11718" cy="5.34057" r="2.09686" fill="#fff" />
                <circle cx="343.46611" cy="5.34057" r="2.09686" fill="#fff" />
                <rect x="671.64772" y="294.80743" width="60" height="31" fill="#e6e6e6" />
                <rect x="671.64772" y="405.80743" width="60" height="31" fill="#e6e6e6" />
                <circle cx="701.64772" cy="365.80743" r="50" fill="#e6e6e6" />
                <circle cx="701.64772" cy="365.80743" r="39" fill="#fff" />
                <rect x="688.87659" y="355.12175" width="30.24683" height="1.72839" fill="#e2e2e2" />
                <rect x="688.84621" y="361.46796" width="46.80151" height="1.72839" fill="#6c63ff" />
                <rect x="688.84621" y="367.44701" width="36.72829" height="1.72839" fill="#e2e2e2" />
                <rect x="688.84621" y="373.42605" width="21.60488" height="1.72839" fill="#e2e2e2" />
                <rect x="688.84621" y="379.4051" width="32.40732" height="1.72839" fill="#e2e2e2" />
                <rect x="675.15972" y="354.62472" width="3.45678" height="3.45678" fill="#e2e2e2" />
                <rect x="675.15972" y="360.60376" width="3.45678" height="3.45678" fill="#6c63ff" />
                <rect x="675.15972" y="366.58281" width="3.45678" height="3.45678" fill="#e2e2e2" />
                <rect x="675.15972" y="372.56186" width="3.45678" height="3.45678" fill="#e2e2e2" />
                <rect x="675.15972" y="378.54091" width="3.45678" height="3.45678" fill="#e2e2e2" />
              </svg>
            </div>
          </div>
        </div>
        <div className='home--platforms'>
          <div>
            <h2 className='platforms--header'>Deployed Platforms</h2>
          </div>
          <div>
            <p>
              We leverage data-informed design and iterative design process to deliver platforms and products that are accessible, available and reliable to improve end-users’ experience.
            </p>
          </div>
          <div className='platform--wrapper'>
            <a href="https://algoboard.com" target="_blank" className='platform--card platform--algoboard--tab tab--algoboard' rel="noreferrer">
              {/* <router-link className="platform--card platform--algoboard--tab tab--algoboard" :to="{ name: 'Products', params: { slug: 'algoboard' }}"> */}
              <div className="flex-equal-inner">
                <div className="flex-equal-content">
                  <div className="platform--header">
                    <svg className="platform--card--icon" viewBox="0 0 2815 2280" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_dddd_364_102)">
                        <path d="M1138.32 478.859V478.859C1305.1 401.089 1503.34 473.245 1581.11 640.023L2132.54 1822.56V1822.56C1965.76 1900.33 1767.52 1828.18 1689.75 1661.4L1138.32 478.859Z" fill="white"/>
                        <path d="M1173.04 553.316L1138.32 478.859L1063.86 513.579C1094.47 514.415 1159.15 523.533 1173.04 553.316Z" fill="white"/>
                      </g>
                      <g filter="url(#filter1_dddd_364_102)">
                        <path d="M1463.3 976.376V976.376C1303.94 884.366 1100.16 938.969 1008.15 1098.33L599.724 1805.74V1805.74C759.089 1897.75 962.869 1843.15 1054.88 1683.78L1463.3 976.376Z" fill="white"/>
                        <path d="M1434.46 1026.33L1463.3 976.375L1534.45 1017.45C1505.93 1012.09 1446 1006.35 1434.46 1026.33Z" fill="white"/>
                        <path d="M628.209 1755.71L599.369 1805.66L528.221 1764.59C556.744 1769.95 616.673 1775.69 628.209 1755.71Z" fill="white"/>
                      </g>
                      <path d="M1249.19 463.371V463.371C1415.97 385.601 1614.21 457.757 1691.98 624.535L2243.41 1807.08V1807.08C2076.63 1884.85 1878.39 1812.69 1800.62 1645.91L1249.19 463.371Z" fill="#FC8917"/>
                      <path d="M1283.91 537.828L1249.19 463.371L1174.73 498.091C1205.34 498.927 1270.02 508.045 1283.91 537.828Z" fill="#FC8917"/>
                      <path d="M2317.86 1772.34L2243.42 1807.09L2208.67 1732.64C2232.66 1751.67 2288.08 1786.24 2317.86 1772.34Z" fill="#FC8917"/>
                      <path d="M1409.08 959.947V959.947C1249.71 867.938 1045.93 922.54 953.925 1081.91L545.502 1789.31V1789.31C704.868 1881.32 908.648 1826.72 1000.66 1667.36L1409.08 959.947Z" fill="#FC8917"/>
                      <path d="M1380.24 1009.9L1409.08 959.948L1480.23 1001.02C1451.7 995.658 1391.78 989.919 1380.24 1009.9Z" fill="#FC8917"/>
                      <path d="M573.987 1739.28L545.147 1789.24L474 1748.16C502.522 1753.53 562.451 1759.26 573.987 1739.28Z" fill="#FC8917"/>
                      <defs>
                        <filter id="filter0_dddd_364_102" x="1057.29" y="447.562" width="1081.82" height="1419.45" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect1_dropShadow_364_102" result="effect2_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect2_dropShadow_364_102" result="effect3_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect3_dropShadow_364_102" result="effect4_dropShadow_364_102"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_364_102" result="shape"/>
                        </filter>
                        <filter id="filter1_dddd_364_102" x="521.649" y="931.681" width="1019.37" height="931.901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect1_dropShadow_364_102" result="effect2_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect2_dropShadow_364_102" result="effect3_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect3_dropShadow_364_102" result="effect4_dropShadow_364_102"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_364_102" result="shape"/>
                        </filter>
                      </defs>
                    </svg>
                    <div className="platform--title">Algoboard</div>
                  </div>
                  {/* <div className="platform--title">Algoboard</div> */}
                  <div className="platform--description">
                    <p>An EdTech SaaS application that provides a range of educational platforms, giving schools and students the opportunity to learn remotely.</p>
                    <p>Its model aggregates learning resources, thereby reducing the total cost and at the same time making these materials accessible, available and reliable at all time.</p>
                  </div>
                </div>
                <div>
                  <span className="platform--card--more">Learn More &rarr;</span>
                </div>
              </div>
            </a>
            <a href="https://algoforms.com" target="_blank" className="platform--card platform--algoforms--tab tab--algoforms" rel="noreferrer">
              <div className="flex-equal-inner">
                <div className="flex-equal-content">
                  <div className="platform--header">
                    <svg className="platform--card--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="">
                      <path fill="#374151" d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                    </svg>
                    <div className="platform--title">Algoforms</div>
                  </div>
                  {/* <div className="platform--title">Algoforms</div> */}
                  <div className="platform--description">
                    <p>A low-code (drag and drop) SaaS application for quick surveys and data acquisition.</p>
                    <p>Quality of data is a critical factors in many business decisions, Algoforms helps to drive actionable insights and tell a compelling story that motivates others to engage and act using data.</p>
                  </div>
                </div>
                <div>
                  <span className="platform--card--more">Learn More &rarr;</span>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className='home--services'>
          <div className="services--header">
            <div className="wrapper--container">
              <h2>Our Services</h2>
              <div className="services--divider"></div>
              <p>
                By adopting continuous improvement and operational excellence, we add real values to end-users by substituting their actual experiences with their desired expectations thereby ensuring optimal differentiations.
              </p>
            </div>
          </div>
          <div className='services--container'>
            <div className="services--item services--list wrapper--container">
              <div className='services--wrapper'>
                <div className="services--title"><h2>Cloud Solutions</h2></div>
                <div className="services--decorator">
                  <div className="decorator--top"></div>
                  <div className="decorator--middle"></div>
                  <div className="decorator--bottom"></div>
                </div>
                <div className="services--content">
                  <p>We architect solutions that are reliable, affordable, and available. Our unique services focus on building resilient system that deliver value to our customers, ensuring business continuity and disaster recovery.</p>
                </div>
              </div>
              <div className='services--wrapper'>
                <div className="services--title"><h2>Data Privacy</h2></div>
                <div className="services--decorator">
                  <div className="decorator--top"></div>
                  <div className="decorator--middle"></div>
                  <div className="decorator--bottom"></div>
                </div>
                <div className="services--content">
                  <p>While efficient delivery of compliant product is a challenge to many organizations, our solutions streamline the process by leveraging and adopting existing and evaluated guiding principles. </p>
                </div>
              </div>
              <div className='services--wrapper'>
                <div className="services--title"><h2>Blockchain</h2></div>
                <div className="services--decorator">
                  <div className="decorator--top"></div>
                  <div className="decorator--middle"></div>
                  <div className="decorator--bottom"></div>
                </div>
                <div className="services--content">
                  <p>At the heart of the blockchain benefits, its building composition and various validation mechanisms is a “trustless technology”. We proffer solution to common use cases.</p>
                </div>
              </div>
              <div className='services--wrapper'>
                <div className="services--title"><h2>Emerging Tech.</h2></div>
                <div className="services--decorator">
                  <div className="decorator--top"></div>
                  <div className="decorator--middle"></div>
                  <div className="decorator--bottom"></div>
                </div>
                <div className="services--content">
                  <p>We are adopting continuous improvement and operational excellence to add real values to end-users by substituting their actual experiences with their desired expectations.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default Home;

// import { Header } from "../partials";
import './Cloud.css';

const Cloud = () => {

  return (
    <div className="cloud">
      {/* <section className="cloud--header">
        <Header />
      </section> */}
      <section className="cloud--main wrapper--container">
        <div className="cloud--title">
          <h1 className="cloud--heading">Cloud Computing </h1>
        </div>
        <div className="cloud--content">
          <p>
            Cloud technologies has not only transformed IT industry but also made scaling IT infrastructures both cost and time effective. It has eliminated the “undifferentiated heavy lifting” for many businesses, thereby availing the opportunity to focus on business decision making.
          </p>
          <p>
            We help organizations with making architecture decisions by recommending cloud solutions that are reliable, affordable, and available. Our unique services focus on building resilient system that deliver value to our customers, ensuring business continuity and disaster recovery.
          </p>
          <p>
            Some of the benefits our customers derive from our services include the following:
          </p>
          <p>
            <ul>
              <li className='cloud--list'><b>Reduced Cost:</b> Our clients focus on business decision making while offloading the task of purchasing, installing, configuring, and managing infrastructure.</li>
              <li className='cloud--list'><b>Scale:</b> Another benefits our services avails our clients is the ability to scale vertically or horizontally thereby allowing them to meet various demands.</li>
              <li className='cloud--list'><b>Agility and Time to Market:</b> We enable our clients to spin out or develop and deploy services within the shortest period.</li>
            </ul>
          </p>
          <p>
            To learn more cloud our various offerings or enquiries, do send us an email &nbsp;<a href="mailto:info@barewave.com">info@barewave.com</a> or call:&nbsp;<a href="tel:+2348032130560">+234 803 213 0560</a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Cloud;

// import { Header } from "../partials/";
import './About.css';

const About = () => {

  return (
    <div className="about">
      {/* <section className="about--header">
        <Header />
      </section> */}
      <section className="about--main wrapper--container">
        <div className="about--title">
          <h1 className="about--heading">About Us</h1>
        </div>
        <div className="about--content">
          <p>
            Barewave is a fast growing Information Technology startup that leverages cloud-based platforms and emerging technologies to solve real life problems across various fields. Our approach to problem solving utilizes software as a tool rather than a solution. This has enabled us to assess constraints holistically while deploying the right software.
          </p>
          <p>
            We are leveraging data-informed design and iterative design process to deliver services and products that are accessible, available and reliable to improve end-users’ experience. Hence, by adopting continuous improvement and operational excellence, we add real values to end-users by substituting their actual experiences with their desired expectations thereby ensuring optimal differentiations.
          </p>
          <p>
            We are building platforms that deliver value-adds to consumers. Equally as important is the ability to leverage our vast experiences and technical know-hows to make trade-offs about technical debts and job prioritization. We do not just love the DAI talk, we walk it by enabling a work-life balance, flexibility, diversity, and a workplace culture where creative ideation and independent thinking is a daily reality.
          </p>
          <p>
            Our sole focus is on the timely delivery of innovative solutions that returns the greatest benefits for the least costs. Hence, by expertly leverageing excellent communication skills, data-informed decisions, and agile methodology, we turn operational data into actionable insights to drive digital transformation and align stakeholders’ expectations with business strategic goals.
          </p>
          <p>
            To learn more about our various offerings or enquiries, send us an email &nbsp;<a href="mailto:info@barewave.com">info@barewave.com</a> or call:&nbsp;<a href="tel:+2348032130560">+234 803 213 0560</a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default About;
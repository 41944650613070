// import { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';
import { GET_BLOGS } from "../../services/index";
// import { Link } from "react-router-dom";
// import { v4 as uuidv4 } from 'uuid';
// console.log(uuidv4())
import { BlogType } from "../../types/index";
// import { Header } from "../partials";
import './Blog.css';

const Blog = () => {
  const { loading, error, data } = useQuery(GET_BLOGS);
  // const [blogs, setBlogs] = useState([]);
  // mutation({ variables: { input: this.state } })
  // useEffect(() => {
  //   setBlogs(data.blogs || []);
  //   // console.log('blogs: ', blogs);
  // }, [data]);

  if (loading) return <p style={{ textAlign: "center" }}>Loading...</p>;
  if (error) return <p style={{ textAlign: "center" }}>Error :(</p>;
  // if (data.blogs.length) {
  //   console.log('blogs: ', data.blogs);
  // }
  console.log('blogs: ', data.blogs);

  return (
    <div className="blog">
      {/* <section className="blog--header">
        <Header />
      </section> */}
      <section className="blog--main wrapper--container">
        <div className="blog--title">
          <h1 className="blog--heading">Latest Blogs</h1>
        </div>
        <div className="blog--content">
          {!!data.blogs.length ? (
            <div className="">
              {data.blogs.map((blog: BlogType) => {
                return (
                  <div key={ blog.blogId } className="blog--item">
                    <div className="blog--header">
                      <div>{ blog.title }</div>
                      <div>{ `${new Date(Number(blog.published_at)).toDateString()} by Callis` }</div>
                    </div>
                    <div className="blog--body">
                      <div>{ blog.content }</div>
                      <a href={ blog.image_source } className="blog--more">Learn More &rarr;</a>
                    </div>
                    <div className="blog--footer">
                      Tags: 
                      { blog.tags.map((tag, i) => (
                        <span  key={i}>{ ` ${tag}, ` }</span>
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="">
              <p>No content available.</p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Blog;